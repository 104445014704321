<template>
    <div class="mb-1">
        <slot class="" name="title">
            <h3 class="mb-0">{{ title }}</h3>
        </slot>
        <div class="list-item pl-md-3">
            <div class="row mb-0 pb-0" v-for="(item, index) in schema" :key="index">
                <div class=" col-12 m-0 p-0 key card bg-dark key-background">
                    <div class="row p-0 m-0">

                        <div class="col-1 m-0 pl-0">
                            <vs-avatar
							icon
                            size="35"
							:color="(
                                item.label == `Tipo` ? `primary` 
                                : item.label == `Nombre` ? `success` 
                                : item.label == `Frecuencia` ? `primary`
                                : item.label == `Persona que debe conocer el resultado` ? `danger`
                                : item.label == `Fuente de la información` ? `success`
                                : item.label == `Fórmula o método de cálculo` ? `primary`
                                : item.label == `Meta o limite del indicador` ? `danger`
                                : item.label == `Responsable de la medición` ? `success` : ``
                            )"
                            class=""
                            style="font-size: 10px !important;"
							>
							<i :class="(
                                item.label == `Tipo` ? `fas fa-list` 
                                : item.label == `Nombre` ? `fas fa-user` 
                                : item.label == `Frecuencia` ? `fas fa-calendar-days`
                                : item.label == `Persona que debe conocer el resultado` ? `fas fa-user-tie`
                                : item.label == `Fuente de la información` ? `fas fa-file-lines`
                                : item.label == `Fórmula o método de cálculo` ? `fas fa-square-root-variable`
                                : item.label == `Meta o limite del indicador` ? `fas fa-triangle-exclamation`
                                : item.label == `Responsable de la medición` ? `fa-solid fa-address-book` : ``) " ></i> 
                             
							
						</vs-avatar>
                        </div>
                        <span class="col ml-0 px-0 mb-0 pr-2 py-2">
                            {{ item.label }}:
                        </span>
                    </div>
                </div>

                
                <span class="value pl-3 pb-0 mb-0 card bg-dark value-background">{{ renderValue(data, item) }}</span>
            </div>
            <slot name="otherItem"></slot>
        </div>
        <slot name="footer"></slot>
        
    </div>
</template>

<script>

export default {
    props: {
        
        schema: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => ({})
        },
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        
        renderValue(data, item) {

            let result = data[item.name];
            if (item.type === "date" && result) {
                return new Date(result).toLocaleDateString();
            }

            
            if (item.name.includes("Id") && item.name.indexOf("Id") === item.name.length - 2 && result) {
                const name = item.name.replace("Id", "");
                if (data[name].tipo) {
                    return data[name].tipo;
                }

                if (data[name].descripcion) {
                    return data[name].descripcion;
                }

                if (data[name].nombres) {
                    return data[name].nombres + " " + data[name].apellidos;
                }
            }

            return result ?? "---";
        }
    }
};
</script>

<style scoped>
.list-item {
    display: grid;
    grid-template-columns: 1fr; 
    grid-row-gap: 0.5rem; 
}

.row {
    display: grid;
    grid-template-columns: auto 1fr; 
    grid-column-gap: 1rem; 
}

.key-background {
    
    padding: 0.5rem;
    padding-bottom: 0% !important;
    width:30vw;
}

.value-background {
    background-color: blue;
    padding: 0.5rem;
    width: 37vw;

}
@media (max-width: 767px) {
        .list-item {
            display: grid;
            grid-template-columns: 1fr; 
            grid-row-gap: 0.5rem; 
        }

        .row {
            display: grid;
            display: flex;
            grid-template-columns:  1fr; 
            grid-column-gap: 1rem; 
        }

        .key-background {
            height: 50px;
            /* padding-top: 2rem; */
            padding-bottom: 0% !important;
            margin-bottom: 10px;
            width:100%;
            padding-top: 10px !important;

        }
        .value-background {
            /* background-color: blue; */
            /* padding: 1rem; */
            height: 50px;

            padding-top: 17px !important;
          margin-top: 10px;
            width: 100%;

        }
}
</style>
<!-- <template>
    <div class="mb-4">
        <slot name="title">
            <h3 class="mb-2">{{ title }}</h3>
        </slot>
        <div class="list-item">
            <div class="item" v-for="(item, index) in schema" :key="index">
                <span class="key">{{ item.label }}:</span>
                <span class="value">{{ renderValue( data, item) }}</span>
            </div>
            <slot name="otherItem"></slot>
        </div>
        <slot name="footer">

        </slot>
    </div>
</template>

<script>

export default {
    props: {
        schema: {
            type: Array,
            default: () => []
        },
        data: {
            type: Object,
            default: () => ({})
        },
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        renderValue( data, item ) {
            let result = data[item.name]
            if( item.type === "date" && result ) {
                return new Date(result).toLocaleDateString()
            }

            // console.log('item', item.name ) 
            //comprueba si el nombre del campo termina en Id y que sean los dos ultimos caracteres
            if( item.name.includes("Id") && item.name.indexOf("Id") === item.name.length - 2 && result ) {
                const name = item.name.replace("Id", "")
                if (  data[name].tipo  )
                {
                    return data[name].tipo 
                }

                if ( data[name].descripcion )
                {
                    return data[name].descripcion
                }

                if ( data[name].nombres )
                {
                    return data[name].nombres + " " + data[name].apellidos
                }
            }

            return result ?? "---"
        }
    }
}
</script>

<style scoped>
.list-item {
    /* background-color: aqua; */
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(min(480px, 100%), 1fr));
    grid-auto-flow: dense;
}

.key {
    color: aliceblue;
}
.item {
    /* background-color: blue; */
    display: grid;
    grid-template-columns: 1fr 1fr;
}

</style> -->


