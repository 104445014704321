var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1"},[_vm._t("title",function(){return [_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.title))])]}),_c('div',{staticClass:"list-item pl-md-3"},[_vm._l((_vm.schema),function(item,index){return _c('div',{key:index,staticClass:"row mb-0 pb-0"},[_c('div',{staticClass:"col-12 m-0 p-0 key card bg-dark key-background"},[_c('div',{staticClass:"row p-0 m-0"},[_c('div',{staticClass:"col-1 m-0 pl-0"},[_c('vs-avatar',{staticStyle:{"font-size":"10px !important"},attrs:{"icon":"","size":"35","color":(
                                item.label == `Tipo` ? `primary` 
                                : item.label == `Nombre` ? `success` 
                                : item.label == `Frecuencia` ? `primary`
                                : item.label == `Persona que debe conocer el resultado` ? `danger`
                                : item.label == `Fuente de la información` ? `success`
                                : item.label == `Fórmula o método de cálculo` ? `primary`
                                : item.label == `Meta o limite del indicador` ? `danger`
                                : item.label == `Responsable de la medición` ? `success` : ``
                            )}},[_c('i',{class:(
                                item.label == `Tipo` ? `fas fa-list` 
                                : item.label == `Nombre` ? `fas fa-user` 
                                : item.label == `Frecuencia` ? `fas fa-calendar-days`
                                : item.label == `Persona que debe conocer el resultado` ? `fas fa-user-tie`
                                : item.label == `Fuente de la información` ? `fas fa-file-lines`
                                : item.label == `Fórmula o método de cálculo` ? `fas fa-square-root-variable`
                                : item.label == `Meta o limite del indicador` ? `fas fa-triangle-exclamation`
                                : item.label == `Responsable de la medición` ? `fa-solid fa-address-book` : ``)})])],1),_c('span',{staticClass:"col ml-0 px-0 mb-0 pr-2 py-2"},[_vm._v(" "+_vm._s(item.label)+": ")])])]),_c('span',{staticClass:"value pl-3 pb-0 mb-0 card bg-dark value-background"},[_vm._v(_vm._s(_vm.renderValue(_vm.data, item)))])])}),_vm._t("otherItem")],2),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }